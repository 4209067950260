<script lang="ts">
  import ThreeDotsScale from '../../icons/ThreeDotsScale.svelte';
  import type { Task } from '../../lib/tasks';
  import AddButton from './AddButton.svelte';
  import Done from './Done.svelte';
  import TaskLine from './TaskLine.svelte';

  let {task} : {task : Task} = $props()
</script>

<TaskLine {task} {title} {button} grouped />

{#snippet title()}
  {task.docRequested}
  {#if task.incorrect}{@render message("Please try again; see email for details", "text-error")}{/if}
  {#if task.optional}{@render message("If applicable", "text-info-700")}{/if}
{/snippet}

{#snippet button()}
  {#if task.loading}
    <button aria-label="Please wait" class="btn btn-sm btn-circle btn-secondary transition duration-150 ease-in-out" disabled>
      <ThreeDotsScale class="w-6 h-6" speed={0.7} />
    </button>
  {:else if !task.done || task.addMore}
    <AddButton done={task.done} />
  {:else}
    <Done />
  {/if}
{/snippet}

{#snippet message(text, color="")}
  <span class={`text-sm block -mt-0.5 leading-tight ${color}`}>{text}</span>
{/snippet}
