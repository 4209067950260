<script lang="ts">
  import type { Snippet } from 'svelte';
  import type { TaskData } from '../lib/tasks';
  import CropImage from './CropImage.svelte';
  import TaskList from './MatterCard/TaskList.svelte';

  let {
    title,
    badges,
    vehicle_image_url,
    tasks,
    children
  } : {
    title : string;
    badges : {label : string; value : string; mobile : boolean}[];
    vehicle_image_url? : string;
    tasks : TaskData[];
    children : Snippet;
  } = $props()
</script>

<div class="rounded bg-white p-4 lg:p-6 flex flex-col gap-2">
  <div class="hidden lg:block overflow-visible">
    {#if vehicle_image_url}<CropImage src={vehicle_image_url} class="float-right w-48 h-24 -mt-2" />{/if}
    {@render h2()}
    {@render badgesList()}
  </div>
  {@render h2({mobile: true})}
  {#if children}
    {@render children()}
  {:else}
    <TaskList {tasks} />
  {/if}
  <div class="lg:hidden grid-cols-[1fr_max-content] grid items-center -mb-1">
    {@render badgesList({mobile: true})}
    {#if vehicle_image_url}<CropImage src={vehicle_image_url} class="w-28 h-14" />{/if}
  </div>
</div>

{#snippet h2({mobile}={mobile: false})}
  <h2 class:mb-2={!mobile} class:text-xl={mobile} class:lg:hidden={mobile} class="text-2xl leading-7 font-medium overflow-x-hidden whitespace-nowrap text-nowrap text-ellipsis">{title}</h2>
{/snippet}

{#snippet badgesList({mobile} = {mobile: false})}
  <div class={`flex flex-wrap gap-1 ${mobile ? "min-w-0 pt-2 pb-1" : ""}`}>
    {#each badges as badge (badge.label)}
      {#if mobile || !badge.mobile}
        <span class="badge badge-ghost inline-block whitespace-nowrap overflow-hidden text-nowrap text-ellipsis max-w-full">
          {badge.label}: {badge.value}
        </span>
      {/if}
    {/each}
  </div>
{/snippet}
