<script lang="ts">
  import {cookieConsent} from "../lib/cookieConsent";
  import Cookie from "~icons/ph/cookie";
  import Dialog from "./Dialog.svelte";

  let { privacyPolicyUrl }: { privacyPolicyUrl: string } = $props();
</script>

{#if !$cookieConsent}
  <Dialog
    autoOpen
    showClose
    class="bg-slate-900 text-gray-100"
    position="bottom"
  >
    <div class="p-2 lg:p-6 flex flex-row-reverse justify-between flex-wrap-reverse gap-8 items-center align-middle">
      <div class="flex gap-4 flex-col-reverse w-full lg:w-auto">
        <button
          class="btn bg-blue-600 outline-blue-700 border-blue-700 hover:bg-blue-700 text-white"
          onclick={() => $cookieConsent = "all"}>Allow App Analytics</button
        >
        <button
          class="btn bg-gray-200 outline-gray-300 hover:bg-gray-300 text-gray-800"
          onclick={() => $cookieConsent = "necessary"}
          >Necessary Cookie Use Only</button
        >
      </div>
      <div class="flex gap-4 lg:gap-6 w-full lg:w-auto">
        <Cookie class="text-white w-1/4 h-1/4 hidden md:block max-h-16 max-w-16" />
        <div class="prose max-w-2xl text-gray-100">
          <h2 class="text-xl font-semibold text-white">
            May we enable app analytics?
          </h2>
          <p>
            We use cookies for core functions like secure login and remembering your choices.
            <b class="text-white"><span class="whitespace-nowrap">This website</span> uses no marketing cookies.</b>
          </p>
          <p>
            With your permission, we use analytics software that helps us understand
            how our clients use the portal website—without capturing any personal information.
            For details, please read our <a
              href={privacyPolicyUrl}
              class="text-blue-400 hover:underline focus:text-blue-400 hover:text-blue-400 whitespace-nowrap"
              target="_blank">Privacy Policy</a
            >.
          </p>
        </div>
      </div>
    </div>
  </Dialog>
{/if}
