<script>
  import Check from "~icons/ph/check";
  import { stringStorage } from "../stores/storage";

  let { name, persistenceKey, options, selected: defaultSelected } = $props();

  const selected = stringStorage(persistenceKey);
  if (defaultSelected) selected.set(defaultSelected);
</script>

<div class="border overflow-hidden border-gray-600 rounded flex flex-col mb-4">
  {#each options as [label, value]}
    <label class="p-3 flex items-center cursor-pointer has-[:checked]:bg-info-200">
      <input bind:group={$selected} {name} {value} type="radio" class="hidden peer" required="true" />
      <span class="invisible check-mark peer-checked:visible">
        <Check class="h-6 w-6 inline-block mr-2" />
      </span>
      {label}
    </label>
    <hr class="last:hidden" />
  {/each}
</div>