import "@hotwired/turbo-rails"
import "../controllers"
import "../lib/turboStreamActions"

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import registerSvelte from "../lib/registerSvelte.svelte"
import AdditionalDocument from "../components/AdditionalDocument.svelte"
import Alert from "../components/Alert.svelte"
import Birthday from "../components/Birthday.svelte"
import CaseTypeSwitcher from "../components/CaseTypeSwitcher.svelte"
import ContactPreferences from "../components/ContactPreferences.svelte"
import CookieBanner from "../components/CookieBanner.svelte"
import CookieConsentForm from "../components/CookieConsentForm.svelte"
import CropImage from "../components/CropImage.svelte"
import Dismissable from "../components/Dismissable.svelte"
import DismissableByInteraction from "../components/DismissableByInteraction.svelte"
import GenericUploadTask from "../tasks/GenericUploadTask.svelte"
import IdentifyFinanceCompanyTask from "../tasks/IdentifyFinanceCompanyTask.svelte"
import Interactions from "../components/Interactions.svelte"
import LeaseEndDateTask from "../tasks/LeaseEndDateTask.svelte"
import Login from "../components/Login.svelte"
import Navigation from "../components/Navigation.svelte"
import PersistedSelect from "../components/PersistedSelect.svelte"
import RespondToFinanceCompanyTask from "../tasks/RespondToFinanceCompanyTask.svelte"
import RevealDocuments from "../components/RevealDocuments.svelte"
import SharePCP from "../components/SharePCP.svelte"
import SharepointFileInput from "../components/SharepointFileInput.svelte"
import TasksToDoNotice from "../components/TasksToDoNotice.svelte"
import UploadFinanceAgreementTask from "../tasks/UploadFinanceAgreementTask.svelte"
import UploadProofOfDamagesTask from "../tasks/UploadProofOfDamagesTask.svelte"
import VehicleMatterCard from "../components/VehicleMatterCard.svelte"

registerSvelte({
  AdditionalDocument,
  Alert,
  Birthday,
  CaseTypeSwitcher,
  ContactPreferences,
  CookieBanner,
  CookieConsentForm,
  CropImage,
  Dismissable,
  DismissableByInteraction,
  GenericUploadTask,
  IdentifyFinanceCompanyTask,
  Interactions,
  LeaseEndDateTask,
  Login,
  Navigation,
  PersistedSelect,
  RespondToFinanceCompanyTask,
  RevealDocuments,
  SharePCP,
  SharepointFileInput,
  TasksToDoNotice,
  UploadFinanceAgreementTask,
  UploadProofOfDamagesTask,
  VehicleMatterCard
})

import onElementExists from "../lib/onElementExists"
// Auto-open any dialog that appears in the DOM and requests auto-open via data-auto-open aka data: {auto_open: true}
onElementExists("dialog[data-auto-open]", dialog => dialog.showModal())
import "../lib/posthog"
