<script lang="ts">
  import { onMount } from "svelte";
  import { cookieConsent } from "../lib/cookieConsent";

  let { privacyPolicyUrl }: { privacyPolicyUrl: string } = $props();
  let userChoice = $state($cookieConsent);
  let input = $state<HTMLInputElement>();

  const onsubmit = () => $cookieConsent = userChoice

  onMount(() => {
    input.form.addEventListener("submit", onsubmit);
    return () => input?.form?.removeEventListener("submit", onsubmit);
  });
</script>

<div class="flex flex-col gap-4">
  <div class="prose">
    <h2 class="text-2xl font-medium">Allow app analytics?</h2>
    <p>
      We use cookies for core functions like secure login and remembering your choices.
      <b>This website uses no marketing cookies.</b>
    </p>
    <p>
      With your permission, we use analytics software that helps us understand
      how our clients use the portal website—without capturing any personal information.
      For details, please read our <a
        href={privacyPolicyUrl}
        class="text-blue-600 hover:underline hover:text-blue-600 focus:text-blue-600"
        target="_blank">Privacy Policy</a
      >.
    </p>
  </div>
  <label>
    <input
      type="radio"
      value="necessary"
      bind:this={input}
      class="radio mr-2"
      bind:group={userChoice}
    />
    Necessary Cookie Use Only
  </label>
  <label>
    <input
      type="radio"
      value="all"
      class="radio mr-2"
      bind:group={userChoice}
    />
    Allow App Analytics
  </label>
</div>
