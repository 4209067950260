<script lang="ts">
  import type { Snippet } from "svelte";
  import {interactions} from "../lib/interactions.svelte"

  let {
    dismiss_with,
    children
  } : {
    dismiss_with: string[];
    children?: Snippet;
  } = $props()
  const shouldDismiss = ({name}) => dismiss_with.includes(name)
  let hidden = $derived(!interactions.initialized || interactions.events.some(shouldDismiss))
</script>
{#if !hidden}
  {@render children()}
{/if}
