const LOCALIZE_DEFAULT = {
  month: "long",
  day: "numeric",
  year: "numeric",
} as const

// Cache formatter(s) because they can be used many times on a page, and according to
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/toLocaleDateString,
// reusing a formatter can make formatting much faster.
const formatters = new Map()
function getFormatter(options){
  const key = JSON.stringify(options)
  let formatter = formatters.get(key)
  if(!formatter) {
    formatter = new Intl.DateTimeFormat(undefined, options)
    formatters.set(key, formatter)
  }
  return formatter
}

export function localizeDate(dateInput: string | Date, options: Intl.DateTimeFormatOptions = LOCALIZE_DEFAULT) {
  if (!dateInput) {
    return;
  }

  let date: Date;
  if (dateInput instanceof Date) {
    // Already a date object
    date = dateInput
  } else if (dateInput.match(/^\d{4}-\d{2}-\d{2}$/)) {
    // "YYYY-MM-DD"
    date = new Date(dateInput + "T00:00:00");
  } else {
    // Assuming this is a full datetime string
    date = new Date(dateInput);
  }

  return getFormatter(Object.assign({}, LOCALIZE_DEFAULT, options)).format(date);
}
