export interface EventsStore {
  events: Interaction[];
  initialized: boolean;
}
export interface Interaction {
  name: string;
}
export let interactions : EventsStore = $state({events: [], initialized: false});

export const appendEvent = (event: Interaction) => {
  interactions.events = [...interactions.events, event];
};

export const setEvents = (events: Interaction[]) => {
  interactions.initialized = true;
  interactions.events = [...events];
};
