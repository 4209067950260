<script lang="ts">
  import Dialog from "./Dialog.svelte";
  import Share from "~icons/ph/chats";
  import WhatsApp from "~icons/ph/whatsapp-logo";
  import Chat from "~icons/ph/chat-text";
  import X from "~icons/ph/x-logo";
  import Email from "~icons/ph/envelope-simple";
  import Copy from "~icons/ph/copy";
  import { fade } from "svelte/transition";
  import revert from "../stores/revert";

  const {
    addUrl,
    shareUrl
  } : {
    addUrl: string;
    shareUrl: string;
  } = $props()

  const messages = {
    "Detailed": "Car dealers have been unfairly charging higher rates on PCP since 2007. The FCA banned the practice in 2021, but you won't get a penny back unless you make a claim. JLG can help.",
    "Friendly": "Hey, did you hear we might’ve been overcharged on our car finance? I’m using JLG to help claim it back—they make it really simple. Have a look:",
    "Link Only": "",
  }

  let dialog = $state<Dialog>()
  let chosen = $state(Object.keys(messages)[0])
  let message = $derived(messages[chosen])
  let fullMessage = $derived(`${message} ${shareUrl}`.trim())
  let messageParam = $derived(encodeURIComponent(fullMessage))

  let copied = revert(false, 3_000)
  async function copyMessage() {
    try {
      await navigator.clipboard.writeText(fullMessage);
      $copied = true;
    } catch (error) {
      console.error(error.message);
    }
  }
</script>
<div class="rounded bg-white p-4 pl-3 lg:p-6 lg:pl-5 border-l-4 border-0 border-solid border-info">
  <h2 class="text-2xl leading-7 font-medium -mt-1 mb-2 lg:mb-3">Friends don't let friends get <span class="whitespace-nowrap">ripped off</span></h2>
  <p class="text-base mb-4 lg:mb-5">Do you know anyone who financed a vehicle between April 2007 and January 2021? <b>They may have a claim worth £1,000s.</b> We'll check their eligibility and help them file their claim, too.</p>
  <div class="flex flex-col sm:flex-row gap-3 items-center justify-between">
    <button class="btn btn-secondary text-base rounded-lg font-medium" onclick={()=>dialog.showModal()}><Share class="w-5 h-5 relative -top-[1px] inline-block" /> Share with a friend</button>
    <a class="text-base text-center sm:text-left font-normal !no-underline" href={addUrl} target="_blank" rel="noopener">
      Did you finance another vehicle?<br>
      <span class="underline font-medium text-primary">Check another VRM&hellip;</span>
    </a>
  </div>
</div>
<Dialog bind:this={dialog} showClose>
  <h3 class="text-xl leading-7 font-medium -lg:mt-1 mb-3">Friends don't let friends get <span class="whitespace-nowrap">ripped off!</span></h3>
  <div class="text-lg font-medium mb-3">How shall we break it to them?</div>
  <div class="rounded-lg border border-solid border-base-200 shadow-inner mb-4">
    <div class="join w-full flex justify-stretch">
      {#each Object.keys(messages) as value}
        <button class="focus:outline-info join-item btn btn-xs sm:btn-sm rounded-es-none rounded-ee-none text-xs sm:text-base rounded-btn flex-grow flex-shrink whitespace-nowrap font-medium" class:btn-blue={value == chosen} onclick={()=>chosen = value}>{value}</button>
      {/each}
    </div>
    <div class="mx-1 px-4 flex flex-col justify-end min-h-60 sm:min-h-48 overflow-hidden relative">
      <output class="bubble py-2 px-3 text-base block bg-blue-600 text-white rounded-2xl my-5 whitespace-pre-wrap">{message} <span class="text-nowrap inline-block align-top underline overflow-x-hidden w-[33ch] max-w-full text-ellipsis">{shareUrl}</span></output>
      <div class="flex items-center gap-1 absolute right-1 top-2">
        {#if $copied}
          <div class="badge badge-sm badge-outline border border-solid bg-white" transition:fade>Copied</div>
        {/if}
        <button class="btn btn-sm btn-circle" onclick={copyMessage}><Copy /></button>
      </div>
    </div>
  </div>
  <div class="flex justify-between px-5">
    <div class="tooltip" data-tip="Send with WhatsApp"><a href="https://wa.me?text={messageParam}" target="_blank" rel="noopener" class="btn shadow btn-circle bg-green-600 hover:bg-green-500 text-white hover:text-white"><WhatsApp class="w-7 h-7" /><span class="sr-only">Send with WhatsApp</span></a></div>
    <div class="tooltip" data-tip="Send as text message"><a href="sms:?body={messageParam}" class="btn shadow btn-circle btn-blue"><Chat class="w-7 h-7" /><span class="sr-only">Send as text message</span></a></div>
    <div class="tooltip" data-tip="Post to X"><a href="https://x.com/intent/tweet?url={encodeURIComponent(shareUrl)}&amp;text={encodeURIComponent(message)}&amp;hashtags=PCP" target="_blank" rel="noopener" class="btn shadow btn-circle bg-black hover:bg-gray-600 text-white hover:text-white"><X class="w-6 h-6" /><span class="sr-only">Post to X</span></a></div>
    <div class="tooltip" data-tip="Send as email"><a href="mailto:?subject={encodeURIComponent("Friends don't let friends get ripped off")}&amp;body={messageParam}" class="btn shadow btn-circle border border-solid border-black bg-base-200 hover:bg-base-100 hover:border hover:border-gray-700"><Email class="w-6 h-6" /><span class="sr-only">Send as email</span></a></div>
  </div>
</Dialog>
<style lang="postcss">
.btn-blue {
  @apply bg-blue-600 hover:bg-blue-500 text-white hover:text-white;
}

.bubble {
  @apply relative;
  /* tail styles substantially stolen from https://samuelkraft.com/blog/ios-chat-bubbles-css */
  &:before {
    width: 20px;
    right: -7px;
    @apply bg-blue-600;
    border-bottom-left-radius: 16px 14px;
  }

  &:after {
    width: 26px;
    @apply bg-white;
    right: -26px;
    border-bottom-left-radius: 10px;
  }

  &:before,
  &:after {
    position: absolute;
    bottom: 0;
    height: 25px; /* height of our bubble "tail" - should match the border-radius above */
    content: '';
  }
}
</style>