<script lang="ts">
  let {
    name,
    options,
    group = $bindable(),
    required = false,
    vertical = true,
    error,
  }: {
    name: string;
    options: string[][];
    group: string;
    vertical?: boolean,
    required?: boolean;
    error?: string;
  } = $props();
</script>

<div class="flex gap-3 {vertical ? "flex-col" : "flex-row"}">
  {#each options as [text, value]}
    <label class="label-text flex flex-row gap-2">
      <input
        type="radio"
        class="radio"
        class:input-error={!!error}
        {name}
        {value}
        bind:group={group}
        {required}
      />
      <span>{text}</span>
    </label>
  {/each}
</div>

{#if error}
  <div class="text-error">{error}</div>
{/if}
