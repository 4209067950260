<script lang="ts">
  let {
    model,
    existingSmsOptIn,
    existingWhatsAppOptIn,
    collapseWhatsAppMessage = false,
    selectOptIn = false,
  }: {
    model: string;
    existingSmsOptIn: boolean;
    existingWhatsAppOptIn: boolean;
    collapseWhatsAppMessage: boolean;
    selectOptIn: boolean;
  } = $props();

  let smsOptIn = $state(existingSmsOptIn?.toString());
  let whatsAppOptIn = $state(existingWhatsAppOptIn?.toString());

  if (selectOptIn) {
    smsOptIn = "true";
    whatsAppOptIn = "true";
  }
</script>

<div class="flex flex-col gap-4">
  <div class="grid-container max-w-md">
    <p>Email (required)</p>
    <label class="bg-yellow-100 rounded-t">
      <input type="radio" class="radio" checked required />
      <span>Yes</span>
    </label>
    <label>
      <input type="radio" class="radio !opacity-100" disabled />
      <span>No</span>
    </label>

    <hr class="col-span-3 border-t-0 border-b-2" />

    <p>WhatsApp</p>
    <label class="bg-yellow-100">
      <input
        type="radio"
        class="radio"
        name="{model}[whatsapp_opt_in]"
        value="true"
        bind:group={whatsAppOptIn}
        required
      />
      <span>Yes</span>
    </label>
    <label>
      <input
        type="radio"
        class="radio"
        name="{model}[whatsapp_opt_in]"
        value="false"
        bind:group={whatsAppOptIn}
      />
      <span>No</span>
    </label>

    <p>Text message</p>
    <label class="bg-yellow-100 rounded-b">
      <input
        type="radio"
        class="radio"
        name="{model}[sms_opt_in]"
        value="true"
        bind:group={smsOptIn}
        required
      />
      <span>Yes</span>
    </label>
    <label>
      <input
        type="radio"
        class="radio"
        name="{model}[sms_opt_in]"
        value="false"
        bind:group={smsOptIn}
      />
      <span>No</span>
    </label>
  </div>

  <p class="text-gray-600 text-sm">
    By opting in, you agree to receive SMS messages from Johnson Law Group.
    Standard rates may apply.
  </p>

  <div
    class="bg-info-200 text-center rounded py-3 px-2"
    class:invisible={whatsAppOptIn !== "true" && !collapseWhatsAppMessage}
    class:hidden={whatsAppOptIn !== "true" && collapseWhatsAppMessage}
  >
    <p class="text-base">
      WhatsApp coming soon!
      <br />
      <strong class:hidden={smsOptIn !== "false"}
        >May we text you in the meantime?</strong
      >
      <strong class:hidden={smsOptIn !== "true"}
        >We will text you in the meantime.</strong
      >
    </p>
  </div>
</div>

<style>
  .grid-container {
    @apply grid items-center;
    grid-template-columns: 1fr auto auto;
  }

  label {
    @apply cursor-pointer text-center p-2;

    & > span {
      @apply label-text pl-1;
    }
  }
</style>
