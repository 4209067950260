<script lang="ts">
  import Form from "../components/Form.svelte";
  import Car from "~icons/ph/car-profile-fill";
  import { domId } from "../lib/dom";

  let {
    initial_values, end_reason_options, action, errors, check_trade_in_url
  } : {
    // main task
    initial_values : { [key: string]: string | null };
    end_reason_options : Array<{label: string, value: string, date_label: string | false}>;
    action : string;
    errors : { [key: string]: string } | null;
    // check trade-in
    check_trade_in_url? : string;
    // other_cases? : Array<string>
  } = $props()

  // main task logic
  let selectedReason = $state(initial_values.end_reason || null)
  let dateLabel = $derived.by(()=>{
    for(const option of end_reason_options) {
      if(option.value == selectedReason) return option.date_label;
    }
    return false;
  })
  const endDateId = domId("end-date")
  const closeDialogId = domId("close-dialog")
</script>
{#if !check_trade_in_url}
  <Form method="put" {action}>
    <div class="flex flex-col gap-3">
      <div>
        <div class="label font-medium text-lg">When did your agreement end?</div>
        <div class="form-control">
          {#each end_reason_options as option}
            <label class="label cursor-pointer justify-start gap-2" class:input-error={errors?.end_reason}>
              <input type="radio" name="end_reason" bind:group={selectedReason} value={option.value} class="radio" required>
              <span class="label-text">{option.label}</span>
            </label>
          {/each}
          {#if errors?.end_reason}<div class="text-error mt-2">{errors.end_reason}</div>{/if}
        </div>
      </div>
      <div class="form-control w-full" class:invisible={!dateLabel}>
        <label for={endDateId} class="label font-medium text-lg">{dateLabel}</label>
        <input id={endDateId} type="date" name="end_date" class="input input-bordered w-full max-w-xs" class:input-error={errors?.end_date} disabled={!dateLabel} required={!!dateLabel} value={initial_values.end_date || ""}>
        <div class="label max-w-xs">
          <span class="label-text-alt">If you are unsure of the date, just get as close as you can. Even the correct year helps.</span>
        </div>
        {#if errors?.end_date}<div class="text-error mt-1">{errors.end_date}</div>{/if}
      </div>
      {#if errors?.general}<div class="text-error">{errors.general}</div>{/if}
      <div class="flex justify-between">
        <button class="btn btn-primary" type="submit">Done</button>
        <button class="btn btn-ghost" form={closeDialogId}>Cancel</button>
      </div>
    </div>
  </Form>
{:else}
  <div class="flex flex-col gap-3">
    <div class="alert alert-info">
      <Car class="w-8 h-8"/>
      <p class="text-base">
        {#if selectedReason == "trade-in"}
          Based on your trade-in date, your new vehicle purchase
        {:else}
          Based on your end date, your subsequent vehicle purchase
        {/if}
        may also be eligible for a PCP claim.
      </p>
    </div>
    <div class="flex justify-between">
      <a href={check_trade_in_url} class="btn btn-primary hover:text-white active:text-white visited:text-white" target="_blank" rel="noopener">Check Eligibility</a>
      <button class="btn btn-ghost" form={closeDialogId}>Close</button>
    </div>
  </div>
{/if}
<form method="dialog" id={closeDialogId}></form>
<style lang="postcss">
  .label { @apply pl-0; }
</style>
