<script lang="ts">
  import CaretRight from "~icons/ph/caret-right";
  import { localizeDate } from "../lib/date";

  let {
    label,
    documents,
    displayMatterLabel = false
  }: {
    label: string;
    documents: {name: string, type: string, created_at: string, matter_label: string | null}[];
    displayMatterLabel: boolean;
  } = $props();
</script>

<div class="rounded bg-neutral-100">
  <details class="group">
    <summary class="cursor-pointer list-none [&::-webkit-details-marker]:hidden">
      <div class="text-sm p-2 flex items-center gap-2">
        <CaretRight class="group-open:rotate-90 w-4 h-4 inline-block transition-transform"/>
        <span>{label}</span>
        <span class="badge badge-sm badge-secondary font-bold">{documents.length}</span>
      </div>
    </summary>
    <div class="text-sm">
      {#each documents as document}
        <div class="py-2 px-3 border-t border-t-neutral-200 border-solid first:border-t-0 first:pt-0">
          <div class="flex justify-between flex-wrap">
            <b class="break-all font-medium">{document.name}</b>
            <span class="text-right flex-grow">{localizeDate(document.created_at, {month: "short", year: "2-digit"})}</span>
          </div>
          {#if displayMatterLabel}
            <span>{document.matter_label}</span>
            {#if document.type}<span> - </span>{/if}
          {/if}
          <span>{document.type}</span>
        </div>
      {/each}
    </div>
  </details>
</div>
