<script lang="ts">
  import type { Snippet } from "svelte";
  import { stringStorage } from "../stores/storage";
  let {
    name,
    form = false,
    children
  } : {
    name: string;
    form?: boolean;
    children?: Snippet<[()=>void]>;
  } = $props()

  const dismissed = stringStorage(`dismiss-${name}`)
  const dismiss = () => { $dismissed = 'y' }
</script>
{#if !$dismissed}
  {#if form}
    <form class="contents" onsubmit={(e)=>{
      e.preventDefault();
      e.stopImmediatePropagation();
      dismiss();
    }}>{@render children(dismiss)}</form>
  {:else}
    {@render children(dismiss)}
  {/if}
{/if}