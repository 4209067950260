<script lang="ts">
  // 3-dots-scale from https://icon-sets.iconify.design/svg-spinners/?tag=Contains+Animations&attribution=0&commercial=1
  import { scaleTemplate } from "../lib/formatting";

  let {
    class: className = null,
    speed = 1
  } = $props()
  let scale = scaleTemplate(1/speed)
</script>
<svg xmlns="http://www.w3.org/2000/svg" class={className} width="24" height="24" viewBox="0 0 24 24">
  <circle cx="4" cy="12" r="3" fill="currentColor">
    <animate id="svgSpinners3DotsScale0" attributeName="r" begin={scale`0;svgSpinners3DotsScale1.end-${0.25}s`} dur={scale`${0.75}s`} values="3;.2;3"/>
  </circle>
  <circle cx="12" cy="12" r="3" fill="currentColor">
    <animate attributeName="r" begin={scale`svgSpinners3DotsScale0.end-${0.6}s`} dur={scale`${0.75}s`} values="3;.2;3"/>
  </circle>
  <circle cx="20" cy="12" r="3" fill="currentColor">
    <animate id="svgSpinners3DotsScale1" attributeName="r" begin={scale`svgSpinners3DotsScale0.end-${0.45}s`} dur={scale`${0.75}s`} values="3;.2;3"/>
  </circle>
</svg>
