export function partition<T>(list : Iterable<T>, filterFn : (item: T)=>boolean) {
  const yes = [], no = [];
  for(const item of list){
    if(filterFn(item)) yes.push(item);
    else no.push(item);
  }
  return [yes, no]
}

export function* chunkWhile(list, criteriaFn) {
  let last
  let group = []
  for(const item of list) {
    if(last === undefined || criteriaFn(last, item)) {
      group.push(item)
    } else {
      yield group
      group = [item]
    }
    last = item
  }
  if(group.length) yield group;
}
