export function shuffle<T>(array : T[]) : T[]{
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export type SavedOrder<T> = Map<T, number>

export function saveRestoreOrder<T>(keyFn : (any)=>T) {
  const save = (items : any[]) => items.reduce((map : SavedOrder<T>, item, i)=>map.set(keyFn(item), i), new Map())
  const restore = (items : any[], order : SavedOrder<T>) => {
    const restored = [...items]
    restored.sort((a, b)=>{
      const ai = order.get(keyFn(a)), bi = order.get(keyFn(b))
      if(typeof ai == "undefined" || typeof bi == "undefined") return 0;
      return ai - bi
    })
    return restored
  }
  return [save, restore] as const
}