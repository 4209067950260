<script lang="ts">
  let {
    name,
    options,
    required = false,
    error,
  }: {
    name: string;
    options: string[][];
    required?: boolean;
    error?: string;
  } = $props();
</script>

<div class="flex flex-col gap-2">
  {#each options as [text, value]}
    <label class="label-text flex flex-row gap-2">
      <input
        type="checkbox"
        class="checkbox"
        class:input-error={!!error}
        name={`${name}[${value}]`}
        {required}
      />
      <span>{text}</span>
    </label>
  {/each}
</div>

{#if error}
  <div class="text-error">{error}</div>
{/if}
