<script lang="ts">
  import ConfettiIcon from "~icons/ph/confetti-duotone"
  import Confet from "js-confetti";
  import { shuffle } from "../lib/array";

  let {
    closeInteraction,
    celebrateInteraction,
    timing,
    name
  } : {
    closeInteraction: string,
    celebrateInteraction: string
    timing: string,
    name: string
  } = $props()

  const conf = new Confet()
  let get_emojis = () => shuffle(["🌈", "⚡️", "💥", "✨", "💫", "🌸", "💐", "🎉"]).slice(0,3)

  const messages = {
    early: ["early", "have"],
    today: ["", "are having"],
    late: ["belated", "had"],
  }

  const [adjective, verb] = messages[timing]

  function celebrate(e){
    conf.addConfetti({emojis: get_emojis()})
  }
</script>
<div class="relative rounded bg-white p-4 pl-3 lg:p-6 lg:pl-5 border-l-4 border-0 border-solid border-info" id="birthday_top">
  <h2 class="text-base mr-6 sm:mr-0 font-bold sm:text-2xl sm:leading-tight sm:font-medium -mt-1 mb-1 lg:mb-2">Happy {adjective} birthday, {name}!</h2>
  <div class="text-base">
    From the team at Johnson Law Group, we hope you {verb} a great day.

    <button class="btn btn-secondary flex mt-3 lg:mt-5 font-medium" type="button" onclick={celebrate} data-interaction={celebrateInteraction}>
      <ConfettiIcon class="w-5 h-5 relative -top-[1px] inline-block"/> Celebrate!
    </button>
  </div>
  <button data-interaction="{closeInteraction}" type="button" class="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">✕</button>
</div>