<script lang="ts">
  import Form from "../components/Form.svelte";
  import SharepointFileInput from "../components/SharepointFileInput.svelte";
  import RadioGroup from "./utils/RadioGroup.svelte";
  import FloatingFooter from "./utils/FloatingFooter.svelte";
  import { domId } from "../lib/dom";
  import type { Snippet } from 'svelte';

  let {
    action,
    taskQuestion,
    documentName,
    createUploadSessionUrl,
    uploadAttachmentExtensions,
    errors,
    children,
  }: {
    action: string;
    taskQuestion: string;
    documentName: string;
    createUploadSessionUrl: string;
    uploadAttachmentExtensions: string[];
    errors: { [key: string]: string } | null;
    children?: Snippet;
  } = $props();

  let selectedOption = $state("yes");

  const closeDialogId = domId("close-dialog");
</script>

<Form method="patch" {action}>
  <div class="flex flex-col gap-3 text-base">
    <div>{taskQuestion}</div>
    <RadioGroup
      name="has_document"
      options={[
        ["Yes", "yes"],
        ["No", "no"],
      ]}
      bind:group={selectedOption}
      required={true}
      vertical={false}
    />

    <div class="form-control w-full mb-3" class:invisible={selectedOption !== "yes"}>
      <SharepointFileInput
        name={documentName}
        accept={uploadAttachmentExtensions}
        {createUploadSessionUrl}
        required={selectedOption === "yes"}
        multiple={true}
      />
      {#if errors?.document_missing}<div class="text-error">
          {errors.document_missing}
        </div>{/if}
      {#if children}
        <div class="mt-3">{@render children()}</div>
      {/if}
    </div>

    {#if errors?.general}<div class="text-error">{errors.general}</div>{/if}

    <FloatingFooter>
      <button class="btn btn-primary" type="submit">Send</button>
      <button class="btn btn-ghost" form={closeDialogId}>Cancel</button>
    </FloatingFooter>
  </div>
</Form>

<form method="dialog" id={closeDialogId}></form>
