import {SvelteURL} from "svelte/reactivity"

export function reactiveLocation(){
  const currentLocation = new SvelteURL(location.href)
  $effect(()=>{
    const onTurboVisit = event => currentLocation.href = event.detail.url;
    document.documentElement.addEventListener("turbo:visit", onTurboVisit);
    return ()=>{
      document.documentElement.removeEventListener("turbo:visit", onTurboVisit);
    }
  })
  return currentLocation;
}

export function csrfToken(){
  const name = document.querySelector("meta[name=csrf-param]")?.getAttribute("content")
  const value = document.querySelector("meta[name=csrf-token]")?.getAttribute("content")
  return {name, value}
}

function isString(v){
  return typeof v == "string" || v instanceof String;
}

type ResponseFormat = "json"|"text"|"blob"

export async function fetchRailsData(request: Request | URL | string, config: RequestInit | ResponseFormat = {}, responseFormat?: ResponseFormat): Promise<any>{
  if(responseFormat === undefined && typeof config === "string") {
    responseFormat = config
  }
  responseFormat ||= "json"
  if(!isString(config) && (isString(request) || request instanceof URL)) {
    request = new Request(request, config);
  }
  if(request instanceof Request) {
    const headers = request.headers
    const key = "Content-Type"
    const type = headers.get(key)
    // With Rails endpoints, safe to assume that any plain text is actually a JSON string
    if(!type || type.startsWith("text/plain")) {
      headers.set(key, "application/json")
    }
    headers.set("X-CSRF-Token", csrfToken().value)
  }
  const response = await fetch(request);
  switch(responseFormat){
    case "json": return response.json();
    case "blob": return response.blob();
    case "text": return response.text();
    default: throw `Unsupport responseFormat="${responseFormat}" in fetchRailsData`;
  }
}

export function sendBeacon(url, dataObj: {[key: string]: string}){
  const data = new FormData();
  const {name, value} = csrfToken();
  data.append(name, value);
  for(const [key, value] of Object.entries(dataObj)){
    data.append(key, value);
  }
  return navigator.sendBeacon(url, data);
}

export function extractSigned(signed: string){
  return JSON.parse(atob(signed.split("--")[0]));
}
