<script lang="ts">
  import { flip } from "svelte/animate";
  import { chunkWhile } from "../../lib/iterable";
  import { Task, type TaskData } from "../../lib/tasks";
  import UploadTaskList from "./UploadTaskList.svelte";
  import TaskLine from "./TaskLine.svelte";
  import { saveRestoreOrder, type SavedOrder } from "../../lib/array";

  let {tasks: tasksData} : {tasks : TaskData[]} = $props()
  let tasks : Task[] = $state([])
  let animatedList = $derived.by(
    // Assumes Rails has provided tasks in the right order with all Upload Document tasks clustered together
    ()=>[...chunkWhile(tasks, (a, b)=>a.docRequest == b.docRequest)].flatMap(chunk=>{
      // Group at least 2 Upload Document tasks; otherwise produce a flat list
      if(!chunk[0].docRequest || chunk.length < 2) return chunk;
      return {tasks: chunk, path: "UPLOADS", groupComponent: UploadTaskList};
    })
  )

  const [saveOrder, restoreOrder] = saveRestoreOrder(task=>task.path)
  let lastOrder : SavedOrder<string> = null
  let reorderTimeout: number
  $effect(()=>{
    clearTimeout(reorderTimeout)
    let newTasks = Task.fromArray(tasksData)
    if(lastOrder) {
      reorderTimeout = setTimeout(()=>tasks = newTasks, 600)
      tasks = restoreOrder(newTasks, lastOrder)
    } else {
      tasks = newTasks
    }
    lastOrder = saveOrder(newTasks)
  })
</script>

<div class="flex flex-col gap-2 -mx-2 lg:mx-0">
  {#each animatedList as item (item.path)}
    <div animate:flip={{duration: 400}}>
      {#if item.groupComponent}
        <item.groupComponent tasks={item.tasks} allTasks={tasks} />
      {:else}
        <TaskLine task={item} />
      {/if}
    </div>
  {/each}
</div>
