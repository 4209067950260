<script lang="ts">
  import {appendEvent, setEvents, type Interaction} from "../lib/interactions.svelte"
  import { extractSigned, sendBeacon } from "../lib/railsUtil.svelte";
  let {
    interactions,
    interactions_path
  } : {
    interactions: Interaction[];
    interactions_path: string;
  } = $props()

  // Keep global interactions state up to date with interactions prop passed to this component
  $effect(() => setEvents(interactions))

  // Log clicks on elements with data-interaction="<< signed interaction >>" property
  const attribute = "data-interaction"
  function logInteraction(event: MouseEvent) {
    const interactor = (event.target as Element)?.closest(`[${attribute}]`);
    if (event.defaultPrevented || !interactor) return;
    const signedInteraction = interactor.getAttribute(attribute);
    const plainInteraction = extractSigned(signedInteraction);
    appendEvent(plainInteraction);
    sendBeacon(interactions_path, {interaction: signedInteraction})
  }
</script>
<svelte:window onclick={logInteraction} />