export function pluralPhrase(count: number, word: string, pluralWord: string | null = null){
  return `${count} ${(count == 1) ? word : (pluralWord ?? `${word}s`)}`;
}
export function plural(count: number, word: string, pluralWord: string | null = null){
  return (count == 1) ? word : (pluralWord ?? `${word}s`);
}
export function scaleTemplate(factor, decimalPlaces = 3) {
  return function (strings, ...values) {
    let out = strings[0]
    for(let i = 0; i < values.length; i++){
      out += (factor * values[i]).toFixed(decimalPlaces)
      out += strings[i + 1]
    }
    return out
  }
}
