<script lang="ts">
  import { slide } from "svelte/transition";
  import { localizeDate } from "../../lib/date";
  import { jsonStorage } from "../../stores/storage";
  import FolderButton from "./FolderButton.svelte";
  import Counter from "./Counter.svelte";
  import Done from "./Done.svelte";
  import AddButton from "./AddButton.svelte";
  import { UploadedDocument, type Task } from "../../lib/tasks";
  import { type Snippet } from "svelte";
  import ThreeDotsScale from "../../icons/ThreeDotsScale.svelte";
  let {
    task,
    title = null, // optional snippet, uses task.title otherwise
    button = null, // optional snippet, uses standalone task button logic otherwise
    grouped = false // styling tweaks for when presented in a group, as in "Upload documents"
  } : {
    task: Task,
    title?: Snippet,
    button?: Snippet,
    grouped?: boolean
  } = $props()

  // Show the list of documents if user chose to OR they just uploaded some
  let oldTask
  let showNew = $state(false) // will be set to true if props were updated with new documents
  let showDocuments = jsonStorage(`${task.path}:showDocuments`) // use jsonStorage instead of $state so the app remembers how you left it
  let showing = $derived(showNew || $showDocuments)
  let documents = $state(task.docsUploaded)
  $effect(()=>{
    if(!!oldTask && UploadedDocument.markNew(oldTask.docsUploaded, task.docsUploaded)){
      // queueMicrotask makes this happen after TaskList's animate:flip calculates targets.
      // Otherwise, opening the folder or adding a document changes the height and animate:flip
      // undesirably scales the whole TaskLine, even when no order has changed.
      queueMicrotask(()=>{
        showNew = true
        documents = task.docsUploaded
      })
    }
    oldTask = task
  })
  const toggle = ()=>{
    $showDocuments = !showing || null // null instead of false because null deletes the localStorage entry entirely
    showNew = false // user toggle cancels showNew override
  }
</script>
<form action={task.path} data-turbo-frame="task-modal" class={`
  alert border-l-4 border-0 border-solid ${task.borderClass}
  grid-flow-row grid-cols-1 justify-items-stretch text-left gap-y-0
  ${grouped
    ? "bg-transparent rounded-none px-2 py-1.5 lg:px-4 lg:py-2 last:pb-3 lg:last:pb-3"
    : "bg-white shadow p-2 lg:p-4"}
`}>
  <div class="w-full grid grid-flow-col grid-cols-[auto_minmax(auto,_1fr)] items-center gap-2 lg:gap-4" class:opacity-50={task.deferred} class:pointer-events-none={task.deferred}>
    <!-- Icon with or without folder -->
    {#if task.docsUploaded.length}
      <FolderButton onclick={toggle} icon={task.icon} open={showing} count={task.docsUploaded.length} />
    {:else}
      <div class="flex gap-2 items-center">
        <task.icon class="w-6 h-6" />
        {#if task.unavailable}<Counter count={0} />{/if}
      </div>
    {/if}
    <!-- Title -->
    <span class="text-sm lg:text-base">
      {#if title}
        {@render title()}
      {:else}
        {task.title}
      {/if}
    </span>
    <!-- Button/Done -->
    {#if button}
      {@render button()}
    {:else if task.done && task.addMore}
      <AddButton done />
    {:else if task.done}
      <Done />
    {:else if task.deferred}
      <span class="text-sm lg:text-base">Not Available Yet</span>
    {:else}
      <button
        aria-label={task.loading ? "Please wait" : task.buttonLabel}
        data-turbo-submits-with={task.buttonLabel}
        data-disable-with={task.buttonLabel}
        disabled={task.loading}
        class="btn btn-sm px-2 btn-secondary font-medium text-sm rounded-full transition duration-150 ease-in-out relative"
      >
        <span class:invisible={task.loading}>{task.buttonLabel}</span>
        {#if task.loading}<ThreeDotsScale class="w-6 h-6 absolute left-[50%] top-[50%] -mt-3 -ml-3" speed={0.7} />{/if}
      </button>
    {/if}
  </div>
  <!-- Document list -->
  {#if showing && documents.length}
    <div transition:slide={{duration: 200}} class="text-sm -mx-1 pt-3 lg:pt-4 pb-0.5 lg:pb-0 grid grid-cols-1 sm:grid-cols-2 gap-x-3 gap-y-1">
      {#each documents as doc}
        <div class={`
          flex min-w-0 justify-between items-center gap-1 text-nowrap bg-opacity-20 py-0.5 px-2 rounded-full transition-all
          ${doc.isNew ? "bg-success outline outline-2 outline-success" : "bg-neutral-400"}
        `}>
          <doc.icon class="w-4 h-4" />
          <div class="overflow-x-hidden overflow-ellipsis flex-grow">
            {doc.filename}
          </div>
          <div>{localizeDate(doc.created, {month: "short", year: "2-digit"})}</div>
        </div>
      {/each}
    </div>
  {/if}
</form>
