<script lang="ts">
  import FolderOpen from "~icons/ph/folder-open-light"
  import Folder from "~icons/ph/folder-simple-light"
  import Counter from "./Counter.svelte";
  import { fade } from "svelte/transition";

  let {
    onclick,
    open,
    icon: Icon,
    count
  } = $props()
</script>
<button type="button" {onclick} class="flex gap-2 items-center bg-transparent border-none p-0 cursor-pointer">
  <div class="w-6 h-6 relative">
    {#if open}
      <div transition:fade={{duration:150}} class="absolute -left-1 -top-1"><FolderOpen class="w-8 h-8" /></div>
    {:else}
      <div transition:fade={{duration:150}} class="absolute -left-1 -top-1"><Folder class="w-8 h-8" /></div>
    {/if}
    <Icon class={`
      w-[1.125rem] h-[1.125rem] box-content pl-[1px] pt-[1px] bg-[--bg-color] absolute rounded
      transition-transform
      -right-1 -bottom-1 ${open ? "-translate-x-0.5 translate-y-0.5" : ""}
    `} />
  </div>
  <Counter {count} />
</button>
