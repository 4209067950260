<script lang="ts">
  let {count} = $props()
  let warn = $derived(count==0)
</script>
<span
  class:bg-blue={!warn}
  class:text-white={!warn}
  class:bg-warning={warn}
  class:text-black={warn}
  class="rounded-full w-5 h-5 flex justify-center items-center text-xs font-bold"
>{count}</span>
