// Task AND UploadedDocument default icon
import FileText from "~icons/ph/file-text"
// UploadedDocuments
import ImageSquare from "~icons/ph/image-square-duotone"
import FilePdf from "~icons/ph/file-pdf-duotone"
// Tasks
import Upload from "~icons/ph/upload"
import ClipboardText from "~icons/ph/clipboard-text"
import Bank from "~icons/ph/bank"
import CalendarDots from "~icons/ph/calendar"
import IdentificationCard from "~icons/ph/identification-card"
import Wrench from "~icons/ph/wrench"
import Taxi from "~icons/ph/taxi"
import Cake from "~icons/ph/cake"

const TASK_ICONS = {
  "file-text": FileText,
  "upload": Upload,
  "clipboard-text": ClipboardText,
  "bank": Bank,
  "calendar-dots": CalendarDots,
  "identification-card": IdentificationCard,
  "wrench": Wrench,
  "taxi": Taxi,
  "cake": Cake,
} as const;

const STATUS_BORDERS = {
  incorrect: "border-error",
  not_started: "border-warning",
  optional: "border-info",
  deferred: "border-gray",
  pending_review: "border-success",
  completed: "border-success",
  unavailable: "border-success"
}

const TODO_STATUS = ["incorrect", "not_started"] as const
const DONE_STATUS = ["unavailable", "completed", "pending_review", ] as const
const ALL_TASK_STATUS = [...TODO_STATUS, "optional", "deferred", ...DONE_STATUS] as const

export type TaskData = {
  path : string;
  icon : keyof typeof TASK_ICONS;
  status : (typeof ALL_TASK_STATUS)[number];
  title : string;
  add_more : boolean;
  ready : boolean;
  document_requested : string | null;
  documents_uploaded : UploadedDocument[] | null;
}

export type UploadedDocumentData = {
  filename : string;
  created_at : string; // Full datetime string
}

export class Task {
  path : string;
  icon_name : keyof typeof TASK_ICONS;
  status : (typeof ALL_TASK_STATUS)[number];
  title : string;
  #addMore : boolean;
  ready : boolean;
  docRequested : string | null;
  docsUploaded : UploadedDocument[];

  constructor(attrs){
    this.path = attrs.path
    this.icon_name = attrs.icon
    this.status = attrs.status
    this.title = attrs.title
    this.#addMore = attrs.add_more
    this.ready = attrs.ready
    this.docRequested = attrs.document_requested || null
    this.docsUploaded = UploadedDocument.sortedFromArray(attrs.documents_uploaded || [])
  }

  get icon(){ return TASK_ICONS[this.icon_name] }
  get borderClass(){ return STATUS_BORDERS[this.status] }
  get buttonLabel(){ return this.incorrect ? "Correct" : "Respond" }

  // Boolean accessors. Wish we could use ? in method names.
  get addMore(){ return this.#addMore || this.unavailable }
  get incorrect(){ return this.status == "incorrect" }
  get optional(){ return this.status == "optional" }
  get deferred(){ return this.status == "deferred" }
  get unavailable(){ return this.status == "unavailable" }
  get todo(){ return (TODO_STATUS as readonly string[]).includes(this.status) }
  get done(){ return (DONE_STATUS as readonly string[]).includes(this.status) }
  get loading(){ return !this.done && !this.ready }
  get docRequest(){ return !!this.docRequested }

  // Tasks are expected to be priority-sorted on the server already.
  static fromArray(tasksData : TaskData[]) : Task[] {
    return tasksData.map(task => new Task(task))
  }
}

export class UploadedDocument {
  filename;
  created;
  isNew;

  constructor(attrs){
    this.filename = attrs.filename
    this.created = new Date(attrs.created_at)
    this.isNew = false
  }

  // Rails has access to more powerful mime type inference, but for the moment we
  // don't need anything more complicated than this.
  get icon(){
    if(/\.pdf\s*$/i.test(this.filename)) return FilePdf;
    if(/\.(jpe?g|png|heic|gif|bmp|tiff?)\s*$/i.test(this.filename)) return ImageSquare;
    return FileText;
  }

  sameAs(doc){
    if(!doc) return false;
    return this.filename == doc.filename && this.created.getTime() == doc.created.getTime()
  }

  // Most recent first. If you change this, change markNew
  static sortedFromArray(docs) : UploadedDocument[] {
    docs = docs.map(doc => new UploadedDocument(doc))
    docs.sort((a, b)=>b.created.getTime() - a.created.getTime())
    return docs
  }

  // Expects sorted as from sortedFromArray
  static markNew(prev, next) {
    if(!next?.length) return false;
    let anyNew = false
    const last = prev?.[0]
    for(const doc of next) {
      if(doc.sameAs(last)) break;
      anyNew = doc.isNew = true
    }
    return anyNew
  }
}
