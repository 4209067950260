<script lang="ts">
  import { flip } from "svelte/animate";
  import UploadTaskLine from "./UploadTaskLine.svelte";
  import type { Task } from "../../lib/tasks";

  let {tasks, allTasks} : {tasks : Task[]; allTasks : Task[]} = $props()
  let description = $derived.by(()=>{
    if(allTasks.some(t=>t.docsUploaded.length && /DpfIntakeQuestionnaireTask/.test(t.path))) {
      return "If you didn't already provide them with your questionnaire"
    }
    return "Let us know if you don't have them, too"
  })
</script>
<div class="my-1 rounded shadow overflow-clip">
  <div class={`
    text-base px-3 py-3 lg:px-5 lg:py-4 border-l-4 border-0 border-solid leading-snug
    ${tasks[0].borderClass}
  `}>
    <div>Upload Documents</div>
    <div class="text-gray-600 text-sm lg:text-base">{description}</div>
  </div>
  {#each tasks as task (task.path)}
    <div animate:flip={{duration: 400}} class="[--bg-color:theme(colors.white)] bg-[--bg-color] even:[--bg-color:theme(colors.2xlightgray)]">
      <UploadTaskLine {task} />
    </div>
  {/each}
</div>
